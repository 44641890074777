import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import Moment from "react-moment"
import { IContractDetailItem } from "../../types/interfaces/IContractDetail"
import { ContractInfoSegment } from "../contract-information-segment"

export interface IPOSummaryModalProps {
  isOpen: boolean
  setIsOpen: (bool: boolean) => void
  POFormValues: Record<string, any>
  autoNotes: string
  contract: Record<string, any>
  items: IContractDetailItem[]
  submitDisabled: boolean
  submitting?: boolean
  handleSubmit: (any: any) => void
  setIsConfirmedSend: (bool: boolean) => void
}

export const POSummaryModal = ({
  isOpen,
  setIsOpen,
  POFormValues,
  autoNotes,
  contract,
  items,
  submitDisabled,
  submitting,
  handleSubmit,
  setIsConfirmedSend,
}: IPOSummaryModalProps) => {
  function closeModal() {
    setIsOpen(false)
  }

  const formatDate = (date: string): Object => {
    return <Moment format="DD-MM-YYYY">{date}</Moment>
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 font-normal"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <form
            className="fixed inset-0 overflow-y-auto"
            onSubmit={handleSubmit}
          >
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-1/2 px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    PO SUMMARY
                  </Dialog.Title>
                  <div className="m-6">
                    <div className="grid grid-cols-2 text-left sm:grid-cols-3 gap-x-6 gap-y-8">
                      <ContractInfoSegment
                        label="Partner"
                        value={
                          POFormValues?.supplierBranch?.value
                            ? POFormValues.supplierBranch.value.supplierName
                            : "Not Specified"
                        }
                      />
                      <ContractInfoSegment
                        label="Branch"
                        value={
                          POFormValues?.supplierBranch?.value
                            ? POFormValues.supplierBranch.value.supplierAdrName
                            : "Not Specified"
                        }
                      />
                      <ContractInfoSegment
                        label="Partner Contact"
                        value={POFormValues?.supplierContact?.label}
                      />
                      <ContractInfoSegment
                        label="Site Contact"
                        value={contract?.siteContactName}
                      />
                      <ContractInfoSegment
                        label="Delivery Date"
                        value={formatDate(contract?.deliveryDate)}
                      />
                      <ContractInfoSegment
                        label="Hire Start"
                        value={formatDate(contract?.hireStartDate)}
                      />
                      <ContractInfoSegment
                        label="Delivery Charge"
                        value={`£${parseFloat(
                          POFormValues?.deliveryCharge
                        ).toFixed(2)}`}
                      />
                      <ContractInfoSegment
                        label="Collection Charge"
                        value={`£${parseFloat(
                          POFormValues?.collectionCharge
                        ).toFixed(2)}`}
                      />
                    </div>

                    <div className="pt-6 text-left">
                      <ContractInfoSegment
                        label="PO Delivery Notes"
                        value={`${autoNotes} ${POFormValues?.deliveryNotes}`}
                      />
                    </div>

                    <div className="pt-6 text-left">
                      <ContractInfoSegment
                        label="Items Requested"
                        value={items.map((item, i) => {
                          return (
                            <ul>
                              <li className="ml-4 text-left list-disc">
                                <p className="text-left" key={i}>
                                  {item.quantity} x {item.chargePeriod === "SALE" ? item.equipmentDesc : item.stockNumber} @ £
                                  {item.purchaseRate
                                    ? parseInt(
                                        item.purchaseRate.toString()
                                      ).toFixed(2)
                                    : ""}
                                  {item.chargePeriod === "SALE" ? " per unit" : ""}
                                  , FLEET NO. {item.fleetNumber}
                                </p>
                              </li>
                            </ul>
                          )
                        })}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row gap-2 mx-4">
                    <div className="flex flex-1">
                      <button
                        onClick={e => {
                          e.preventDefault()
                          closeModal()
                        }}
                        className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                      >
                        EDIT
                      </button>
                    </div>
                    <div className="flex flex-1">
                      <button
                        type="submit"
                        className={`${
                          submitDisabled ? "cursor-not-allowed" : ""
                        } ${
                          submitting ? "animate-pulse" : ""
                        } w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm`}
                        onClick={() => setIsConfirmedSend(true)}
                        disabled={submitDisabled}
                      >
                        {submitting ? "SUBMITTING..." : "SEND TO PARTNER"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </form>
        </Dialog>
      </Transition>
    </>
  )
}
